.menu-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  background-color: #f2f2f2;
  padding: 0 20px;
}

.menu-button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.menu-button:hover {
  background-color: #3e8e41;
}
